/* index page */
/* Description: This consolidates all the components into an index.html file */
/* Objective: Focus on your work */
/* Components - nav*, hero*, short about*, CTA*, announcements, case study list*, method/workflow*, contact form*, footer* */

import React, { useRef, useEffect, useState } from "react"

// import HeadshotImg from "../assets/headshot.png"
// import LinkedinImg from "../assets/linkedin.png"
// import InstagramImg from "../assets/instagram.png"

import NoiseUrl from "../assets/noise.svg"

// import { color, media, space, colorRgb, fontSize } from "../styles/constants"
import styled from "styled-components"
import { ViewMore } from "../components/Link"

/* Named exports for non-page React components */

import { MainWrapper } from "../components/Wrapper"

import { SiteGrid, FoldContainer } from "../components/Container"


/* Named exports for page React components */
import Layout from "../components/Layout"
import Header from "../components/Header"
import HeroSection from '../sections/hero'
import Footer from "../components/Footer"
import WorkSection from "../sections/work"
import BlogSection from "../sections/blog"
import AboutSection from "../sections/about"
import ContactSection from "../sections/contact"
import { graphql, useStaticQuery } from "gatsby"

// import { keyframes } from "styled-components"

// Layout contains head and the global styles and the children.
// The title of the page is included in the head, so this needs to vary by page.
/*
    <Layout>
        <MainWrapper />
        <Header /> stays common for all pages
        <Container>
            {children}
        </Container>
        <Footer />
    </Layout>


*/


const IndexPage = (props, lottie) => {

    const siteData = useStaticQuery(graphql`
        query{
            site{
                siteMetadata{
                    indexTitle
                }
            }
        }
    `)

    // const [scrollOpacity, setScrollOpacity] = useState(1)
    // const inputRef = useRef()

    // useEffect(() => {
    //     const onScroll = e => {
    //         var scrollPosition = inputRef.current.getBoundingClientRect().top
    //         var scrollThreshold = window.innerHeight - 400

    //         // Flawed logic that stops the view more from being more smooth
    //         // But it works, I guess.

    //         var newOpacity = (scrollPosition - scrollThreshold)/400
    //         setScrollOpacity(Math.min(newOpacity, 0))
    //     };

    //     window.addEventListener("scroll", onScroll)
    //     return () => window.removeEventListener("scroll", onScroll)
    // }, [scrollOpacity])

    return (
        <Layout title={siteData.site.siteMetadata.indexTitle} lottie={lottie}>
            <MainWrapper/>
            <SiteGrid>
                <FoldContainer>
                    <Header />
                    <HeroSection />
                </FoldContainer>
                <WorkSection />
                <BlogSection />
                <AboutSection />
                <ContactSection />
                <Footer />
            </SiteGrid>
        </Layout>
    )
}

export default IndexPage





/*

<ViewMore ref={inputRef} scrollOpacity={scrollOpacity}/>

*/

/*

const inputRef = useRef();
    
    const scrollHandler = () => {

        const [scrollOpacity] = useState(1);

        var scrollPosition = inputRef.current.getBoundingClientRect().top;
        var scrollThreshold = window.innerHeight - 400;

        if (scrollPosition > scrollThreshold){
            scrollOpacity((scrollPosition - scrollThreshold)/400);
            console.log(scrollOpacity);
        }
        else{
            scrollOpacity(0);
            console.log(scrollOpacity);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", scrollHandler);
        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, [scrollOpacity]);


*/


// If you want to use view more as a scroll
/*
import { ViewMore } from "../components/Link"

    const FoldContainer = styled.div`
    display: grid;
    grid-template-rows: auto 1fr auto;
    align-items: center;
    align-content: space-between;
    height: 100vh;
    `

                <FoldContainer>
                    <Header />
                    <HeroSection />
                    <ViewMore />
                </FoldContainer>

*/

// RECYCLE PILE

// const AvailabilityContainer = styled.div`
//     background: linear-gradient(
//         197.32deg,
//         rgba(${colorRgb.white}, 0.7) 23.78%,
//         rgba(${colorRgb.white}, 0.2) 100%
//     );
//     backdrop-filter: blur(0.5rem);
//     padding: 0 ${space.p100};
//     border-radius: 1rem;
//     border: 1px rgba(${colorRgb.lightGrey}, 0.4) solid;
//     box-shadow: 0 0.25rem 0.5rem rgba(${colorRgb.black}, 0.1);
//     grid-column-start: 1;
//     margin: 0 0 ${space.p150} 0;
//     ${media.xs`
//         grid-column-end: 5;
//     `}
//     ${media.md`
//         grid-column-end: 6;
//     `}
//     ${media.xl`
//         grid-column-end: 8;
//     `}
// `

// const AvailabilityText = styled.p`
//     color: ${color.blue400};
//     ${media.xs`
//         display: ${props => (props.optional ? "none" : "block")};
//     `}
//     ${media.md`
//         display: block;
//     `}
// `

// const AvailabilityCtaContainer = styled.div`
//     padding: 1rem 0;
//     ${media.lg`
//         display: grid;
//         grid-template-columns: repeat(2, 1fr);
//         justify-content: stretch;
//         column-gap: 1rem;
//     `}
// `
// const shimmerBg = keyframes`
//     from {
//         background-position: left;
//         text-shadow: 0 0 0 rgba(${colorRgb.white}, 0.1);
//     }
//     to {
//         background-position: right;
//         text-shadow: 0 0 0.25rem rgba(${colorRgb.white}, 0.3);
//     }
// `
// const Headshot = styled.div`
//     ${media.xs`
//         display: none;
//     `}
//     ${media.md`
//         display: block;
//         position: fixed;
//         right: -12vh;
//         bottom: 0;
//         height: 64vh;
//         width: 64vh;
//         mix-blend-mode: multiply;
//         background-image: url(${HeadshotImg});
//         background-size: cover;
//         background-repeat: no-repeat;
//         opacity: 0.88;
//     `}
//     ${media.xl`
//         right: 0;
//         height: 80vh;
//         width: 80vh;
//     `}
// `
// const Social = styled.div`
//     margin: ${space.p150} 0;
//     height: 3rem;
//     grid-column-start: 1;
//     display: grid;
//     grid-column-gap: 1rem;
//     grid-template-columns: repeat(2, 3rem);
//     border: none;
//     background: transparent;
//     transition: border 1s, background 3s, ease 1s;
//     a:hover {
//         border: 2px rgba(${colorRgb.white}, 0.6) solid;
//         border-radius: 100%;
//         background: linear-gradient(
//             90deg,
//             rgba(${colorRgb.acBgRed}, 0.8) 0%,
//             rgba(${colorRgb.acYellow}, 0.5) 100%
//         );
//     }
// `

// const SpotlightLeft = styled.div`
//     display: inline;
//     width: 80rem;
//     height: 80rem;
//     border-radius: 64rem;
//     position: fixed;
//     left: -8rem;
//     top: -8rem;
//     background: linear-gradient(
//         180deg,
//         ${color.blue300} 0%,
//         ${color.acRed} 100%
//     );
//     filter: blur(16rem);
//     transform: rotate(15deg);
//     opacity: 0.8;
//     z-index: -40;
// `
// const SpotlightRight = styled.div`
//     display: inline;
//     width: 50rem;
//     height: 50rem;
//     border-radius: 50rem;
//     position: fixed;
//     right: -16rem;
//     top: -16rem;
//     background: linear-gradient(
//         180deg,
//         ${color.blue100} 0%,
//         ${color.blue400} 100%
//     );
//     filter: blur(8rem);
//     transform: rotate(-180deg);
//     opacity: 0.8;
//     z-index: -40;
// `

/*
    HeadlineShimmer

    background-image:  
    radial-gradient(at 9% 9%, hsla(195,100%,57%,1) 0, transparent 50%),  
    radial-gradient(at 38% 64%, hsla(28,100%,74%,1) 0, transparent 50%),  
    radial-gradient(at 80% 0%, hsla(242,90%,60%,1) 0, transparent 50%),  
    radial-gradient(at 12% 43%, hsla(50,100%,58%,1) 0, transparent 50%),  
    radial-gradient(at 80% 50%, hsla(340,100%,76%,1) 0, transparent 50%),  
    radial-gradient(at 26% 82%, hsla(22,100%,77%,1) 0, transparent 50%),  
    radial-gradient(at 80% 100%, hsla(347,100%,75%,1) 0, transparent 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    color: transparent;
    background-size: 200%;
    background-position: left;
    animation: ${shimmerBg} 2s linear infinite alternate;
*/