import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery, Link } from 'gatsby'

import { space, color, media } from '../styles/constants'
import { BodyText, SectionHeader } from '../components/Type'

import { Container } from '../components/Container'
import { Button, ButtonIcon } from '../components/Button'

const WorkContainer = styled.div`
    padding: ${space.p200} 0;
`
const RoleText = styled(BodyText)`
    color: ${color.acYellow};
`
const FeaturedWorkContainer = styled(Container)`
    margin: ${space.p300} 0;
    align-items: start;
    grid-column-gap: ${space.p300};
`
const FeaturedWorkImgContainer = styled.div`
    display: block;
    margin: 0 0 ${space.p200} 0;

    // full-width regardless
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 9;
    `}
    ${media.xl`
        grid-column-start: 1;
        grid-column-end: 13;
    `}

    > {
        width: 100%;
        height: auto;
    }
`
const FeaturedWorkTitle = styled.h3`
    color: ${color.white};
    font-size: ${space.p200};
    // full-width regardless
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 9;
    `}
    ${media.xl`
        grid-column-start: 1;
        grid-column-end: 13;
    `}
`
const FeaturedContent = styled.div`
    margin: 0;
    padding: 0;
    // full-width on xs and md.  half-width-left on xl+
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 9;
    `}
    ${media.xl`
        grid-column-start: 1;
        grid-column-end: 7;
    `}
`
const FeaturedRole = styled.div`
    margin: 0;
    padding: 0;
    // full-width on xs and md.  half-width-right on xl+
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 9;
    `}
    ${media.xl`
        grid-column-start: 7;
        grid-column-end: 13;
    `}
`
const FeaturedWorkCta = styled.div`
    margin: ${space.p100} 0;
    // occupies 3 columns from the left for xs
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.sm`
        grid-column-start: 1;
        grid-column-end: 3;
    `}
    // occupies 2 columns from the left for devices larger than sm
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 4;
    `}
`

const OtherWorkContainer = styled(Container)`
    margin: 0;
    padding: 0;

    // 1-column layout in xs, sm, md
    ${media.xs`
        display: grid;
        grid-template-columns: 1fr;
    `}

    // 2-column grid in from lg onwards
    ${media.lg`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: ${space.p300};
    `}
`
const OtherWorkItem = styled.div`
    margin: ${space.p400} 0 0 0;
    
    // important
    align-content: start;

    // 1-column in xs, sm (both 4-col) and in md (8-col)
    ${media.xs`
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    `}
    ${media.md`
        display: grid;
        grid-template-columns: repeat(8, 1fr);
    `}

    // 2-column grid in from lg (8-col) onwards and then in xl (12-col)
    ${media.lg`
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    `}
    ${media.xl`
        display: grid;
        grid-template-columns: repeat(6, 1fr);
    `}
`
const OtherWorkTitle = styled(FeaturedWorkTitle)`
    // 1-column or full-width in xs to md
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 9;
    `}

    // 2-column grid in from lg (8-col) onwards and then in xl (12-col)
    ${media.lg`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.xl`
        grid-column-start: 1;
        grid-column-end: 7;
    `}
`
const OtherWorkImgContainer = styled(FeaturedWorkImgContainer)`
`
const OtherWorkContent = styled.div`
    // 1-column or full-width in xs to md
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 9;
    `}

    // 2-column grid in from lg (8-col) onwards and then in xl (12-col)
    ${media.lg`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.xl`
        grid-column-start: 1;
        grid-column-end: 7;
    `}
`
const OtherWorkCta = styled(FeaturedWorkCta)`
    // obeys same constraints as FeaturedWork
`

const HeroImg = styled.div`
    display: block;
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 9;
    `}
    ${media.xl`
        grid-column-start: 1;
        grid-column-end: 13;
    `}

    > {
        width: 100%;
        height: auto;
    }

`

const WorkSection = () => {

    const WorkData = useStaticQuery(graphql `
        query{
            allContentfulCaseStudy(
                sort:{
                    fields: order
                    order: ASC
                }
            ){
                edges{
                    node{
                        title
                        slug
                        order
                        featured
                        preview{
                            preview
                        }
                        role{
                            role
                        }
                        heroImage{
                            file{
                                url
                                contentType
                            }
                        }
                    }
                }
            }
        }
    `)

    return(


        <React.Fragment>
            {WorkData.allContentfulCaseStudy && 
            
                <WorkContainer id ="work">
                    <SectionHeader>Work</SectionHeader>
                    {WorkData.allContentfulCaseStudy.edges.map((edge) => {

                        const singleUrl = `/work/${edge.node.slug}`

                        return(
                            <React.Fragment key={edge.node.slug}>
                                {edge.node.featured &&
                                    <FeaturedWorkContainer>
                                        <FeaturedWorkImgContainer>
                                                {   // if Lottie JSON is uploaded as Hero
                                                    (edge.node.heroImage.file.contentType == "application/json") &&
                                                        <lottie-player src={edge.node.heroImage.file.url}  background="transparent"  speed="0.5"  loop  autoplay></lottie-player>
                                                }
                                                {   // if image is uploaded as Hero
                                                    (edge.node.heroImage.file.contentType != "application/json") &&
                                                        <img src={edge.node.heroImage.file.url} />
                                                }
                                        </FeaturedWorkImgContainer>
                                        <FeaturedWorkTitle>{edge.node.title}</FeaturedWorkTitle>
                                        <FeaturedContent>
                                            <BodyText>{edge.node.preview.preview}</BodyText>
                                        </FeaturedContent>
                                        <FeaturedRole>
                                            <RoleText><strong><em>Role: </em></strong>{edge.node.role.role}</RoleText>
                                        </FeaturedRole>
                                        <FeaturedWorkCta>
                                            <Link
                                                to={singleUrl}
                                            >
                                                <Button>View case study<ButtonIcon className="fas fa-chevron-right"></ButtonIcon></Button>
                                            </Link>
                                        </FeaturedWorkCta>
                                    </FeaturedWorkContainer> 
                                }
                            </React.Fragment>
                        )})}
                        <OtherWorkContainer>
                            {WorkData.allContentfulCaseStudy.edges.map((edge) => {
                            
                            const singleUrl = `/work/${edge.node.slug}`

                            return(
                                <React.Fragment key={edge.node.slug}>
                                        
                                        {!edge.node.featured &&
                                            <OtherWorkItem>
                                                <OtherWorkImgContainer>
                                                    {   // if Lottie JSON is uploaded as Hero
                                                        (edge.node.heroImage.file.contentType == "application/json") &&
                                                            <lottie-player src={edge.node.heroImage.file.url}  background="transparent"  speed="0.5"  autoplay></lottie-player>
                                                    }
                                                    {   // if image is uploaded as Hero
                                                        (edge.node.heroImage.file.contentType != "application/json") &&
                                                            <img src={edge.node.heroImage.file.url} />
                                                    }
                                                </OtherWorkImgContainer>
                                                <OtherWorkTitle>{edge.node.title}</OtherWorkTitle>
                                                <OtherWorkContent>
                                                    <BodyText>{edge.node.preview.preview}</BodyText>
                                                    <RoleText><strong><em>Role: </em></strong>{edge.node.role.role}</RoleText>
                                                </OtherWorkContent>
                                                <OtherWorkCta>
                                                    <Link
                                                        to={singleUrl}
                                                    >
                                                        <Button>View case study<ButtonIcon className="fas fa-chevron-right"></ButtonIcon></Button>
                                                    </Link>
                                                </OtherWorkCta>
                                            </OtherWorkItem>
                                        }
                                </React.Fragment>
                            )})}
                        </OtherWorkContainer>
                        
                </WorkContainer>
            
            }
        </React.Fragment>
            
    )
}

export default WorkSection