import React from 'react'
import styled from 'styled-components'

import { color, media, space, fontSize, lineHeight } from '../styles/constants'
import { Container } from '../components/Container'
import { ButtonIcon, PrimaryButton } from "../components/Button"
import { InlineOptionalText, SiteAnchor } from '../components/Link'

import ResumeUrl from "../assets/files/Ganesh Iyer - Resume.pdf"
import { BodyText } from '../components/Type'

// Inline component; move this to type
const HeadlineShimmer = styled.span`
    color: ${color.acGreen};
    font-weight: 600;
`
const HeroContainer = styled(Container)`
`
const HeadlineContent = styled.div`
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 7;
    `}
    ${media.lg`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.xl`
        grid-column-start: 1;
        grid-column-end: 9;
    `}
`
const BioContent = styled.div`
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 7;
    `}
    ${media.lg`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.xl`
        grid-column-start: 1;
        grid-column-end: 7;
    `}
`
const HeroCta = styled.div`
    margin: ${space.p100} 0;
    grid-column-start: 1;
    grid-column-end: 5;
`

// Move this to Type
const Headline = styled.h2`
    ${media.xs`
        font-size: ${fontSize.f175};
        line-height: ${lineHeight.lh175};
    `}
    ${media.sm`
        font-size: ${fontSize.f250};
        line-height: ${lineHeight.lh250};
    `}
    font-weight: 400;
    color: ${color.white};
    margin: 0;
`

const HeroSection = () => {
    
    return(
        <HeroContainer>
            <HeadlineContent>
                <Headline>I build software systems that are <HeadlineShimmer>impactful</HeadlineShimmer>, <HeadlineShimmer>scalable</HeadlineShimmer>, and <HeadlineShimmer>resilient</HeadlineShimmer>.</Headline>
            </HeadlineContent>
            <BioContent>
                <BodyText>I'm an experienced and methodic designer who builds elegant interfaces, processes, and teams that rally various stakeholders towards a positive strategic outcome.</BodyText>
                <BodyText optional>I'm actively looking for my next Product Design role. <SiteAnchor href={ResumeUrl} target="_blank">Download resume (PDF, ~619kb) <span className="fas fa-file-pdf"></span></SiteAnchor>.</BodyText>
            </BioContent>
            <HeroCta>
                <a
                    href="https://calendly.com/skepticacid/introduction"
                    target="_blank"
                    rel="noreferrer"
                >
                    <PrimaryButton>Set up a call<ButtonIcon className="fas fa-external-link-alt"></ButtonIcon></PrimaryButton>
                </a>
            </HeroCta>
        </HeroContainer>
    )
}

export default HeroSection

/*
<AnchorButton
                    href="https://calendly.com/skepticacid/15min"
                    target="_blank"
                    rel="noreferrer"
                >

*/

/*

<ShortBio>As a systems designer, I design elegant software products that rally stakeholders towards a key strategic outcome.  Through processes, I also build feedback loops that make businesses more than the sum of their parts.</ShortBio>


Through a data-informed approach, I design and develop elegant software products that rally various stakeholders towards a positive strategic outcome. I also define and execute processes that help businesses become more than the sum of their parts.
*/

/*

I combine a strong UX background and a data-informed approach to build elegant software and hardware products.  I also define and execute processes that help businesses achieve key strategic outcomes and become more than the sum of their parts.

*/

/*
Through a data-informed approach, I design and develop elegant software products that rally various stakeholders towards a positive strategic outcome. I also define and execute processes that help businesses become more than the sum of their parts.
*/

/*
<script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
<lottie-player src="https://assets10.lottiefiles.com/packages/lf20_sx5e0zms.json"  background="transparent"  speed="1"  style="width: 1280px; height: 680px;"  loop  autoplay></lottie-player>

*/