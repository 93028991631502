import React from 'react'
import styled from 'styled-components'
import { PrimaryButton, ButtonIcon } from '../components/Button'

import { Container } from '../components/Container'
import { SiteAnchor } from '../components/Link'
import { SectionHeader, BodyText } from '../components/Type'
import { space, media, color } from '../styles/constants'

const ContactContainer = styled(Container)`
    padding: ${space.p200} 0;
`

const ContactContent = styled(Container)`
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 9;
    `}
    ${media.xl`
        grid-column-start: 1;
        grid-column-end: 13;
    `}
`

const ContactDescription = styled.div`
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 9;
    `}
    ${media.lg`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.xl`
        grid-column-start: 1;
        grid-column-end: 7;
    `}
`
const ContactDescriptionEmail = styled.div`
    > ${BodyText}{
        color: ${color.lightGrey};
    }
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
        > ${BodyText}{
            margin: 0 0 ${space.p200} 0;
        }
    `}
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 9;
    `}
    ${media.lg`
        grid-column-start: 5;
        grid-column-end: 9;
        > ${BodyText}{
            margin: ${space.p150} 0;
        }
    `}
    ${media.xl`
        grid-column-start: 7;
        grid-column-end: 13;
    `}
`

const ContactCta = styled.div`
    margin: ${space.p200} 0;
    // occupies 3 columns from the left for xs
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.sm`
        grid-column-start: 1;
        grid-column-end: 3;
    `}
    // occupies 2 columns from the left for devices larger than sm
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 4;
    `}
`

const ContactSection = () => {
    
    return(
        <ContactContainer id="contact">
            <SectionHeader>Contact</SectionHeader>
            <ContactContent>
                <ContactDescription>
                    <BodyText>
                        I’m currently open to new product design opportunities.  <strong>I love working with startups</strong>.  
                    </BodyText>
                    <BodyText>
                        I’m presently based in Toronto, Canada (GMT-4) 🇨🇦.  I'm flexible about working remotely, but I won't be able to consider relocation at this time.
                    </BodyText>
                    <BodyText><br />
                        Get in touch with me directly - <SiteAnchor href="mailto:ganesh@skepticacid.io"
                        target="_blank"
                        rel="noreferrer">ganesh@skepticacid.io <span className="fas fa-envelope"></span></SiteAnchor>.
                    </BodyText>
                </ContactDescription>
                <ContactDescriptionEmail>
                    <BodyText>
                        I also mentor designers and product managers specifically about core skills, product thinking, and career growth.  Reach out to me over email for my availability.
                    </BodyText>
                </ContactDescriptionEmail>
                <ContactCta>
                    <a
                        href="https://calendly.com/skepticacid/introduction"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <PrimaryButton>Set up a call<ButtonIcon className="fas fa-external-link-alt"></ButtonIcon></PrimaryButton>
                    </a>
                </ContactCta>
            </ContactContent>
        </ContactContainer>
    )
}

export default ContactSection