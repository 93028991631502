import React from 'react'
import styled from 'styled-components'
import { color, lineHeight, space, media } from '../styles/constants'
import { graphql, Link, useStaticQuery } from 'gatsby'

import { BodyText, SectionHeader } from '../components/Type'
import { Button } from '../components/Button'

import { Container } from '../components/Container'

const BlogContainer = styled.div`
    padding: ${space.p200} 0;
`
const BlogItem = styled(Container)`
    margin: ${space.p200} 0;
    align-content: start;
`
const BlogDate = styled.div`
    // full-width for xs and sm
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
        justify-self: start;
    `}
    // full-width for md too
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 9;
    `}
    // attached to the right and 3 columns wide for lg and above
    ${media.lg`
        grid-column-start: 1;
        grid-column-end: 4;
        justify-self: end;
    `}
    ${media.xl`
        grid-column-start: 2;
        grid-column-end: 5;
    `}
    height: ${lineHeight.lh250};
    align-content: start;
`
const BlogPreview = styled.div`
    display: grid;
    // full-width for xs and sm
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
        justify-self: start;
        grid-template-columns: repeat(4, 1fr);
    `}
    // full-width for md too
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 9;
        grid-template-columns: repeat(8, 1fr);
    `}
    // attached to the left and 5 columns wide for lg
    ${media.lg`
        grid-column-start: 4;
        grid-column-end: 9;
        grid-template-columns: repeat(5, 1fr);
    `}
    // attached to the left and 7 columns wide for xl
    ${media.xl`
        grid-column-start: 5;
        grid-column-end: 12;
        grid-template-columns: repeat(7, 1fr);
    `}
    justify-self: stretch;
    align-content: start;
    align-items: start;
    padding: 0;
`
const BlogTitle = styled.h3`
    // full width within the BlogPreview container
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
        line-height: ${lineHeight.lh200};
    `}
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 9;
    `}
    // lg BlogPreview is 5 columns wide
    ${media.lg`
        grid-column-start: 1;
        grid-column-end: 6;
        line-height: 44px;
        // How annoying
    `}
    // xl BlogPreview is 7 columns wide
    ${media.xl`
        grid-column-start: 1;
        grid-column-end: 8;
    `}
    margin: 0;
    color: ${color.white};
    font-size: ${space.p200};
    vertical-align: baseline;
`
const BlogCta = styled.div`
    // 2 columns regardless
    grid-column-start: 1;
    grid-column-end: 3;
`

// Move this to Type and make it consistent across
const PublishedDate = styled(BodyText)`
    line-height: ${lineHeight.lh250};
    margin: 0;
    color: ${color.acGreen};
    vertical-align: baseline;
`
// Move this to Type and make it consistent across
const BlogPreviewText = styled(BodyText)`
    // full width within the BlogPreview container
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 9;
    `}
    // lg BlogPreview is 5 columns wide
    ${media.lg`
        grid-column-start: 1;
        grid-column-end: 6;
    `}
    // xl BlogPreview is 7 columns wide
    ${media.xl`
        grid-column-start: 1;
        grid-column-end: 8;
    `}
`
// Export
const BlogSection = () => {

    const BlogData = useStaticQuery(graphql `
        query{
            allContentfulBlog {
                totalCount
                edges{
                    node{
                        title
                        slug
                        publishedDate (formatString: "LL")
                        preview{
                            preview
                        }
                    }
                }

            }
            site{
                siteMetadata{
                  minBlogCount
                }
              }
        }
    `)
    
    return(
        <React.Fragment>
            {
                BlogData.allContentfulBlog.totalCount >= BlogData.site.siteMetadata.minBlogCount &&
                <BlogContainer id="blog">
                    <SectionHeader>Blog</SectionHeader>
                        {
                            BlogData.allContentfulBlog.edges.map((edge) => {

                                const singleUrl = `/blog/${edge.node.slug}`

                                return(
                                    <BlogItem>
                                        <BlogDate>
                                            <PublishedDate>{edge.node.publishedDate}</PublishedDate>
                                        </BlogDate>
                                        <BlogPreview>
                                            <BlogTitle>{edge.node.title}</BlogTitle>
                                            <BlogPreviewText>{edge.node.preview.preview}</BlogPreviewText>
                                            <BlogCta>
                                                <Link to={singleUrl}>
                                                    <Button facontent="\f138">Read blog</Button>
                                                </Link>
                                            </BlogCta>
                                            
                                        </BlogPreview>
                                    </BlogItem>
                                )
                            })
                        }
                </BlogContainer>
            }
        </React.Fragment>
    )
}

export default BlogSection