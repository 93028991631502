import React from 'react'
import styled from 'styled-components'
import { color, space, media } from '../styles/constants'

import { Container } from '../components/Container'
import { SiteLink, SiteAnchor } from '../components/Link'
import { SectionHeader, BodyText, UlText, LiText } from '../components/Type'

import HeadshotImg from "../assets/headshot.jpeg"
import ResumeUrl from "../assets/files/Ganesh Iyer - Resume.pdf"

const AboutContainer = styled(Container)`
    padding: ${space.p200} 0;
`
const AboutContent = styled(Container)`
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 9;
    `}
    ${media.xl`
        grid-column-start: 1;
        grid-column-end: 13;
    `}
`
const AboutHeadshot = styled.div`
    display: block;
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.sm`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 1;
        grid-column-end: 4;
    `}
    ${media.lg`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.xl`
        grid-column-start: 2;
        grid-column-end: 6;
    `}
    align-self: start;
    justify-self: end;
    overflow: clip;

    img{
        border: 1px dashed ${color.acBgBlue};
        border-radius: 50%;
        display: block;
        // luminosity, overlay 1, lighten 1, hard-light 1, color-dodge
        ${media.xs`
            margin: ${space.p200} auto;
            width: 100%;
        `}
        ${media.sm`
            margin: ${space.p200} auto;
            width: 75%;
            height: auto;
        `}
        ${media.md`
            margin: ${space.p200} ${space.p100} 0 auto;
            width: 100%;
            height: auto;
        `}
        ${media.lg`
            width: 75%;
        `}
        ${media.xl`
            margin: ${space.p200} auto;
            width: 100%;
        `}
    }
`
const AboutDescription = styled.div`
    ${media.xs`
        grid-column-start: 1;
        grid-column-end: 5;
    `}
    ${media.md`
        grid-column-start: 4;
        grid-column-end: 9;
    `}
    ${media.lg`
        grid-column-start: 5;
        grid-column-end: 9;
    `}
    ${media.xl`
        grid-column-start: 7;
        grid-column-end: 13;
    `}
    margin-bottom: ${space.p200};
    align-self: start;
`

const AboutSection = () => {
    
    return(
        <AboutContainer id="about">
            <SectionHeader>About</SectionHeader>
            <AboutContent>
                <AboutHeadshot>
                    <img src={HeadshotImg} />
                </AboutHeadshot>
                <AboutDescription>
                    <BodyText>
                        Hello, I'm Ganesh Iyer.&nbsp; I’m an experienced product generalist with a strong background in user experience and systems design. &nbsp;In the last 6 years especially, I've worked with startups to deliver the best-in-class work across the stack and <em>at speed</em>.
                    </BodyText>
                    <BodyText>
                        Through my work, I strive to create a world where technology is not only intuitive and delightful to use, but also makes us <strong><em>better humans</em></strong>.
                    </BodyText>
                    <BodyText>
                        My achievements include <SiteAnchor href="https://best.berkeley.edu/2017/05/13/elena-duran-and-her-team-win-the-2017-chi-game-for-a-purpose-award/#:~:text=Geometris%2C%20a%20game%20developed%20by,Game%20for%20a%20Purpose%20Award." target="_blank" rel="noreferrer"> winning the Games for a Purpose award at CHI 2017 in Denver, CO <span className="fas fa-external-link-alt"></span></SiteAnchor>.
                    </BodyText>
                    <BodyText>
                        Most recently I had:<br /><br />
                        <UlText>
                            <LiText>
                                created a no-code platform where brands can build mobile apps that 3x-ed the conversion compared to their web stores
                            </LiText>
                            <LiText>
                                built a conversational AI <SiteLink to="/work/conversational-ai-sms">that saves every sales agent 1.5 hours per day (est.) in responding to repeated inquiries</SiteLink>
                            </LiText>
                            <LiText>
                                designed a business-logic driven framework for delivering <SiteLink to="/work/conversational-ai-webchat">hyper-personalized conversations through a webchat</SiteLink>.
                            </LiText>
                        </UlText>
                    </BodyText>
                    <BodyText>
                        <SiteLink to={ResumeUrl} target="_blank">Download resume (PDF) <span className="fas fa-file-pdf"></span></SiteLink>
                    </BodyText>
            </AboutDescription>
            </AboutContent>
        </AboutContainer>
    )
}

export default AboutSection

/*

<ShortBio>As a systems designer, I design elegant software products that rally stakeholders towards a key strategic outcome.  Through processes, I also build feedback loops that make businesses more than the sum of their parts.</ShortBio>


Through a data-informed approach, I design and develop elegant software products that rally various stakeholders towards a positive strategic outcome. I also define and execute processes that help businesses become more than the sum of their parts.
*/
